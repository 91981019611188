<template>
	<div class="layui-bg-gray zong" style="min-height: 300px;">
		<div class="layui-container" style=" ">
			<div class="layui-row" style="max-width: 1200px;margin: 40px auto;margin-bottom:0px;">
				<div class="layui-col-md3">
					<div style="text-align: justify;margin-top: 50px;line-height: 24px;">
						<div class="">
							<img :src="require('@/assets/images/logo.png')" style="width:80%"/>
						</div>
						<p style="margin-top: 20px;color:hsl(220deg,1.86%,68.43%);text-indent:26px;">
							Contrimetric is shortened from Contribution Metric, which is called Article Citation
							Contribution
							Indicator(ACCI), or Contribution Factor(CF). Each academic paper has two ACCI values:
							ACCI-I(CF1)
							and ACCI-II(CF2), which is a new designed indicator for scientific contribution.
							<br>
							<p style="color:hsl(220deg,1.86%,68.43%);text-align:justify ;"> <span style="font-weight: bold;">Publication 1:</span> 
							<!-- <br> -->
							<span style="font-style: italic"> Contrimetric: Article Citation Contribution Indicator for Paper Impact Evaluation</span></p>
							 <p ><a style="color:hsl(220deg,1.86%,68.43%);" href="http://dx.doi.org/10.1017/S1092852923006181">http://dx.doi.org/10.1017/S1092852923006181</a></p>
							 <!-- When it is
							positive, it shows that
							this paper had brought positive contribution comparing to average citations each paper
							receiving in
							terms of citation contribution in its journal impact factor. The ACCI/CF formula is defined
							as ACCI=(C-JIF)/JIF, where C is
							citations number, JIF is Journal Impact Factor in certain year. -->
						</p>
					</div>
				</div>

				<div class="layui-col-md4 ">
					<div style="margin:78px 0px 0px 80px;line-height: 24px;">
						<div class="bottom_title"><strong>CONTACT
								INFO</strong></div>
						<div class="bottom_middle"><img :src="require('@/assets/images/position.png')"
								alt=""><span>B-7-28 Horizon Suites Blok B, Kota Warisan 43900 Sepang, Selangor,
								Malaysia</span></div>
						<div class="bottom_middle"><img :src="require('@/assets/images/email.png')" alt=""
								style="width:17px;height:13px;"><span>
									
									<!-- 	href="mailto:contact@contrimetric.com" -->
									
									
									<a style="color:hsl(220,1.86%,68.43%);" target="_top" 
								
									href="mailto:794532995@qq.com?cc=123456@qq.com&bcc=7890@qq.com&subject=Hello%20World&body=这是我用超链接创建的邮件!"
									>
									contact@contrimetric.com</a>
									
									
									<!-- <a href="https://www.baidu.com" target="_top">点击创建邮件!</a> -->
									
									<!-- <el-link :underline='false' href="mailto:contact@contrimetric.com">
										contact@contrimetric.com
									</el-link> -->
									</span></div>
									
					</div>
				</div>

				<div class="layui-col-md4">
					<div style="margin:96px 0px 0px 80px;line-height: 24px;">
						<div class="bottom_title"><span style="display: flex;width:375px;font-size: 26px;
		color: hsl(182.4deg, 64.1%, 92.35%);"><strong>SOCIAL MEDIA</strong> </span></div>
						<p style="color:hsl(220deg,1.86%,68.43%);">Please follow our activities on the following social
							media platforms</p>
						<div class="bottom_right">
							<img :src="require('@/assets/images/f.png')" alt=""><img
								:src="require('@/assets/images/fly.png')" alt="">
							<img :src="require('@/assets/images/in.png')" alt=""><img
								:src="require('@/assets/images/camera.png')" alt="">
						</div>
					</div>
				</div>
			</div>
			<div style="max-width: 1200px;margin: 40px auto;margin-bottom:0px;padding:10px 0px;color:#fff;display: flex;">
				<div style="">Copyright © 2024 All Rights Reserved by <span style='color:aqua'>Contrimetric</span></div>
				<div style="margin-left:452px;cursor: pointer;" @click="tipPolicy">Terms of use | Privacy policy</div>
			</div>
		</div>
		
	</div>

</template>
<script>
	export default {
		name: "app-footer",
		data() {
			return {
			};
		},
		methods:{
			tipPolicy(){
				this.$router.push('/policy')
			}
		}

	};
</script>
<style scoped lang="scss">
	.zong {
		background-image: url(../assets/images/bgBottom.png)
	}

	.bottom_middle {
		display: flex;
		color: hsl(220deg, 1.86%, 68.43%);
		align-items: flex-start;
		margin: 5px 0px;

	}

	.bottom_middle>img {
		margin: 6px 5px 0px 0px;
		width: 22px;
		height: 20px;
	}

	.bottom_title {
		font-size: 26px;
		color: hsl(182.4deg, 64.1%, 92.35%);
		margin-bottom: 20px;
	}

	.bottom_title>strong {
		display: block;
		width: 230px;
		padding: 18px 0px;
		border-bottom: 1px dotted hsl(182.4deg, 64.1%, 92.35%);
	}

	.bottom_right {
		width: 200px;
		display: flex;
		justify-content: space-between;
		margin: 15px 0px;
	}

	.bottom_right>img {
		width: 24px;
		height: 24px;
	}
</style>