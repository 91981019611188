<template>
	<div>
		<section class="header-bg">
			<div class="container-1200" style="padding-top: 40px;">

				<div class="layui-fluid" style="margin-top: 200px;">
					<div style="text-align: center">
						<h1 style="font-weight: 700;color: white;font-size: 36px">Enhancing Innovation with Data</h1>
					</div>
					<div class="layui-row" style="margin-top: 80px;">
						<!-- 	<div class="layui-col-md10">
							<input type="text" id="articleTitle" autocomplete="off" placeholder="Article Title"
								class="layui-input" v-model="articleTitle">
						</div>
						<div class="layui-col-md2">
							<div class="layui-btn-container">
								<button type="button" style="background-color: #1da6b8;" class="layui-btn layui-btn-fluid" id="search"
									@click="articleDetails">Search</button>
							</div>
						</div> -->

						<div class="layui-form-item">
							<div class="layui-input-group" style="width:100%">
								<input type="text" id="articleTitle" autocomplete="off"
									placeholder="Enter Article Title" class="layui-input" v-model="articleTitle">
								<div class="layui-input-split layui-input-suffix"
									style="cursor: pointer;background-color: #1da6b8;border:none;width:6%;"
									@click="articleDetails">
									<i id="search" class="layui-icon layui-icon-search" style=" color: #fff;"></i>
									<span style="color:#fff;margin:5px 0px;">Search</span>
								</div>
							</div>
						</div>

						<div class="header_bottom">
							<div class="first">
								<div class="first0">Articles</div>
								<div>3M</div>
							</div>
							<div class="second">
								<div class="first0">OA Articles</div>
								<div>1M</div>
							</div>
							<div class="third">
								<div class="first0">Citations</div>
								<div>1.3B</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>
		<!-- ai enhance plugin -->
		<div class="box">
			<div class="box1">
				<div class="box1_left">
					<div>AI Enhanced Plugin</div>
					<ul>
						<li>Real-time Updates: notification with the latest citations</li>
						<li>Boost Impact Factor: access timely cuttingedge researches.</li>
						<li>Visual insights: intuitive visualizations data.</li>
						<li>Easy Integration: Seamless plugin for a user-friendly experience.</li>
					</ul>
				</div>
				<div class="box1_right">
					<img :src="require('@/assets/images/aboutR.png')" alt="">
				</div>
			</div>
		</div>
		<div class="box_1">
			<div class="box_11">
				<img :src="require('@/assets/images/index_widget.png')" alt="">
			</div>
		</div>
		<div class="box_2">
			<div class="box_21">
				<div>Request Code and Application</div>
				<div>This plugin is <span style="color: red;font-weight: bold;">FREE</span> to use. lf you're interested
					in using it, please leave your contact information,and someone will reach out to you shortly.</div>
				<div style="display: flex;justify-content: space-between;width: 100%;"><el-input class="elInput"
						v-model="firstName" placeholder="First Name *"></el-input>
					<el-input v-model="lastName" class="elInput" placeholder="Last Name *"></el-input>
				</div>
				<div style="width: 100%;margin:20px 0px 40px 0px;">
					<el-input v-model="workEmail" style="width: 100%;" placeholder="Work Email *"></el-input>
				</div>
				<div style="margin-left: 30px;"><el-checkbox v-model="isAllowSend">Send me emails about Contrimetric
						products and updates</el-checkbox>
					<p style="margin: 5px 0px 5px 25px;color:#000000">For complete details please visit
						<span style="text-decoration: underline;cursor: pointer;"
							@click="tipWorks">https://www.contrimetric.com/works</span>
					</p>
					<p style="margin: 5px 0px 10px 25px;color:#000000"> in touch at
						contact@contrimetric.com
					</p>
				</div>
				<div style="display: flex;justify-content: center;align-items: center;"><el-button
						@click="submit">Request</el-button></div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				articleTitle: "",
				firstName: '',
				lastName: '',
				workEmail:'',
				isAllowSend: false, //是否发送关于Contrimetric的产品				
			};

		},
		methods: {

			articleDetails() {
				this.$router.push({
					name: "journalSource",
					query: {
						"articleTitle": this.articleTitle
					}
				});
			},
			tipWorks() {
				this.$router.push('/works')
			},
			submit() {
				let paramap = {
						firstName: this.firstName,
						lastName: this.lastName,
						workEmail:this.email,
						isAllowSend: this.isAllowSend,
					}
					const loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					$http.postI("user/insertUserForm", paramap, true).then(res => {
						if(res.code == 200){
							this.$message.success("提交成功")
							loading.close()
						}
					}).catch(err => {
						console.log(err);
					});
			}
		},

		created() {},
		mounted() {

		},
		watch: {

		}
	};
</script>
<style scoped>
	.header_bottom {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.first,
	.second,
	.third {
		/* flex:1; */
		width: 30%;
		margin: 40px 0px;
		color: #fff;
		font-size: 48px;

	}

	.first0 {
		border-bottom: 1px solid #fff;
		font-size: 16px !important;
	}

	.box {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.box1 {
		width: 65%;
		margin: 40px 0px 30px 0px;
		display: flex;
		justify-content: space-between;
	}

	.box1_left>div:nth-child(1) {
		font-family: MicrosoftTaiLe-Bold;
		font-size: 30px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #000000;
		padding-top: 30px;
	}

	.box1_left>ul {
		margin: 30px 0px 0px 10px;
	}

	.box1_left>ul {
		list-style-type: none;
		/* 取消默认的列表项样式 */
	}

	.box1_left>ul li {
		position: relative;
		/* 设置列表项的定位为相对定位 */
		padding-left: 20px;
		/* 增加左边距，以留出位置放置实心圆 */
		font-family: ArialMT;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #000000;
	}

	.box1_left>ul li::before {
		content: "•";
		/* 自定义实心圆标记 */
		font-size: 30px;
		color: #1da6b8;
		/* 设置实心圆的颜色为红色 */
		position: absolute;
		/* 将实心圆绝对定位 */
		left: 0;
		/* 将实心圆放置在列表项的最左边 */
		top: 44%;
		/* 将实心圆的顶部与列表项文本的中部对齐 */
		transform: translateY(-50%);
		/* 使用 translateY 将实心圆向上移动自身一半的高度，以使其与文本垂直居中 */
	}

	.box_1 {
		width: 100%;
		margin: 40px 0px 60px 0px;
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.box_11 {
		width: 80%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.box_11>img {
		width: 80%;
	}

	.box_2 {
		width: 100%;
		/* height: 500px; */
		background-image: url(../assets/images/bg2.jpg);
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.box_21 {
		width: 40%;
		padding: 120px 0px 60px 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;

	}

	.box_21>div:nth-child(1) {
		font-family: MicrosoftTaiLe-Bold;
		font-size: 30px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		color: #000000;
		text-align: center;
	}


	.box_21>div:nth-child(2) {
		margin: 30px 0px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 19px;
		letter-spacing: 0px;
		color: #001111;
	}

	::v-deep .el-input__inner::placeholder {
		color: #000000;
	}

	::v-deep .el-input__inner::placeholder::after {
		content: '*',
	}

	.elInput {
		width: 48%;
	}

	::v-deep .box_21 .el-input__inner {
		background-color: transparent;
		border: 1px solid black;
	}

	::v-deep .box_21 .el-checkbox__inner {
		background-color: transparent;
		border: 1px solid black;

	}

	::v-deep .box_21 .el-checkbox {
		color: #000000;
	}

	.box_21 .el-button {
		margin-top: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20%;
		background-color: #1da6b8;
		border-radius: 5px;
		border: none;
		color: #fff;
		font-size: 20px;
		padding: 19px 60px 18px 60px;
	}
</style>