<template>
	<div class="metting">
		<!-- <app-header v-if="header_show" @search="searchKey"></app-header> -->
		<!-- 		 -->
		<app-header 
		v-if="header_show"></app-header>
		<div class="navContent" v-bind:class="{ navContentWidth: header_show }">
			<div style="height: 0px;" v-if="header_show">

			</div>
			<router-view v-on:header="header" v-on:footer="footer"></router-view>
		</div>
		<app-footer v-if="footer_show"></app-footer>
	</div>
</template>
<script>
	import Header from "./components/meetingHead";
	import Footer from "./components/meetingBottom";
	export default {
		data() {
			return {
				isShowQR: false,
				header_show: true,
				footer_show: true,
			};
		},
		created() {
			
			console.log(this.header_show)
		
		},
		components: {
			"app-header": Header,
			"app-footer": Footer,
		},
		//token是否超时
		mounted() {
			const currentTime = Date.now() 
			if(this.$store.getters.getExpireTime < currentTime){
					this.isLogin = false
					this.$store.commit('setTokenInfo', "")
					this.$store.commit('setUserInfo', "")
					this.$store.commit('setIsLogin', "")
			}
		},
		methods: {
			mouseOverQR() {
				this.isShowQR = true
			},
			mouseLeaveQR() {
				this.isShowQR = false
			},
			//是否显示头部
			header: function(bool) {
				
				this.header_show = bool;
			},
			//是否显示底部
			footer: function(bool) {
				this.footer_show = bool;
			},
			returnTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 50)
			},
			returnKefu() {
				window.open('http://wpa.qq.com/msgrd?v=3&uin=' + 2372522993 + '&site=qq&menu=yes', '_brank');
			}

		},
	};
</script>

<style lang="scss" scoped>
	/* 导航下页面 */
	.metting {
		// padding-top: 2%;
	}

	.navContent {
		// margin: 0 auto;
		color: #333333;
		min-height: 780px;
		padding-bottom: 30px;
		background-color: #fff;
	}

	.navContentWidth {
		width: 100%;
	}
</style>

./configure --prefix=/usr/local/nginx --conf-path=/usr/local/nginx/conf/nginx.conf  --error-log-path=/usr/local/nginx/logs/error.log --pid-path=/usr/local/nginx/logs/nginx.pid  --http-log-path=/usr/local/nginx/logs/access.log --with-http_gzip_static_module --with-http_stub_status_module --with-http_ssl_module
