import router from "./router";
import store from "./store";

import "./assets/css/style.css";
import "./assets/layui/css/layui.css"

import axios from "axios";

import 
	Vue
 from "vue";
 
 
import ECharts from "vue-echarts";
import VueClipboard from "vue-clipboard2";
import App from "./App.vue";
import { Callbacks } from "jquery";


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// 设置语言
locale.use(lang)
Vue.config.productionTip = false

Vue.prototype.$store = store 
Vue.prototype.$axios = axios;
//.use(axios)
	
Vue.use(router)
	.use(ElementUI)

	
	.use(ECharts)
	.use(VueClipboard)
	
new Vue({
	  render: h => h(App),
	  router,
	  store
	}).$mount('#app')
	
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})