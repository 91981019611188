<template>
	<article style="background:repeating-linear-gradient(to right,#202E43,#254E5F,#276268,#0C8A8D);height: 45px;">
		<div class="container-1200">
			<div class="layui-fluid" style="height: 40px;">
				<div class="layui-row" style="color: white;line-height: 45px;">
					<div class="layui-col-md6">
						Welcome to Article Citation Contribution Indicator!
					</div>
					<div class="layui-col-md6 layui-col-right float-hand" v-show="!isLogin">
						<a href="/login" style="color: white">Log in</a>
					</div>
					<div class="layui-col-md6 layui-col-right" v-if="isLogin">
						<p><span class="username" style="margin-right: 30px">
								{{$store.getters.getUserInfo}}
							</span><span class="float-hand logout" @click="loginOut">Log out</span></p>
					</div>
				</div>
			</div>
		</div>
		<section class="header-bg" style="min-height: 750px;">
			<div class="container-1200" style="padding-top: 40px;">
				<div class="layui-fluid">
					<div style="display: flex;">
						<div class="">
							<img :src="require('@/assets/images/logo.png')" />
						</div>
						<div id="warp-menu" style="margin:25px 0px 0px 160px;" >
							
							<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
								 @select="handleSelect" active-text-color="#1da6b8">
								<el-menu-item index="/index">Home</el-menu-item>
								<el-menu-item index="/about">About us</el-menu-item>
								<el-menu-item index="/Ducuments">Ducuments</el-menu-item>
								<!-- <el-menu-item index="/Author"> Author</el-menu-item> -->
								
								<el-submenu index="/article" id="child">
									<template slot="title">Widget</template>
									<el-menu-item index="/works">How it works</el-menu-item>
									<el-menu-item index="/ApplyPlugin">Join Contrimetric Family</el-menu-item>
									<el-menu-item index="/customers">Customers</el-menu-item>
								</el-submenu>
								<el-menu-item index="/Help">Help & Support</el-menu-item>
								
							</el-menu>
						</div>
					</div>
				</div>
			</div>
		</section>
	</article>
</template>

<script>
	import $http from "@/request/http";
	export default {
		name: "app-header",
		data() {
			return {
				//是否登录
				isLogin: this.$store.getters.getIsLogin,
				userInfo: this.$store.getters.getTokenInfo,
				routerR: true,
				activeIndex: "/index",
			};
		},
		watch: {
			// 监听路由变化
			"$route.path": {
				handler(routePath) {
					this.initMenuActive(routePath);
				},
				immediate: true,
			},
			//监听登录状态
			"$store.getters.getIsLogin": {
				handler(isLogin) {
					this.isLogin = isLogin
				},
				immediate: true,
			},
			
		},
		
		methods: {
			initMenuActive(routePath) {
				this.activeIndex = routePath;
				if (routePath == '/') {
					this.activeIndex = '/index'
					
					
				}
			},
			loginOut() {
				$http.loginTrue("logout")
					.then(res => {

						this.$store.commit('setTokenInfo', "")
						this.$store.commit('setUserInfo', "")
						this.$store.commit('setIsLogin', "")

						this.$message.success({
							showClose: true,
							duration: 1500,
							message: "success logout",
						});

					})
					.catch(function() {});
			},
			//导航栏跳转
			handleSelect(index) {
			  this.$router.push(index);
			},

		},
		created() {
			// this.userInfo = this.$store.getters.getUserInfo
			// this.isLogin = this.$store.getters.getIsLogin

			// this.userInfo = localStorage.getItem("userInfo")==""?"":JSON.parse(localStorage.getItem("userInfo"))
		},

	};
</script>
<!-- 内部样式更改 -->
<style>
	/* 悬浮外部li */
	/* body>.el-menu--horizontal>ul>li{
		background-color: transparent !important;
		
	} */
	/* body>#child > .el-menu>.el-submenu>.el-submenu__title:hover{
		background-color:1da6b8;
	} */
	body>.el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
		background-color:#1da6b8;
		color:#fff !important;
	}
	
</style>
<style lang="scss">
	.index-meeting .el-image__inner {
		vertical-align: bottom !important;
	}
	//导航栏的修改
	
	
	#warp-menu > .el-menu{
		background-color: transparent !important;
	}
	
	 #warp-menu> .el-menu.el-menu--horizontal{
		border:none;
	}
	#warp-menu> .el-menu--horizontal>.el-menu-item{
		color:#fff;
	}
	#warp-menu> .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
		color:#fff;
	}
	#warp-menu> .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
	 #warp-menu>
	 .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover{
		background-color: transparent !important;
	}
	
	//子导航栏
	//#warp-menu>.el-menu>.el-submenu为子集的li模块
	#warp-menu>.el-menu>.el-submenu>.el-submenu__title{
		color: #fff;
		
	}
	
	#warp-menu>.el-menu>.el-submenu>.el-submenu__title:hover{
		background:transparent !important;
	}
	
	//二级菜单导航箭头颜色
	#warp-menu>.el-menu>.el-submenu>.el-submenu__title i{
		color: #fff;
	}
	
	

	
	
</style>