// import {
// 	createRouter,
// 	createWebHistory
// } from "vue-router"; //createWebHashHistory
// import home from "../views/index.vue";

import Vue from 'vue'

import VueRouter from 'vue-router'
import home from "../views/index.vue";



Vue.use(VueRouter)

const routes = [

	{
		path: "",
		name: "",
		component: () => import("../views/index.vue"),
	},
	{
		path: "/index",
		name: "index",
		component: home,
		meta: {
			index: 1,
			title: 'Home'
		},
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login.vue"),
		meta: {
			index: 2,
			title: 'Login'
		},
	},
	{
		path: "/Ducuments",
		name: "Ducuments",
		component: () => import("../views/journal/Ducuments.vue"),
		meta: {
			index: 3,
			title: 'Ducuments'
		},
	},
	{
		path: "/author",
		name: "author",
		component: () => import("../views/author.vue"),
		meta: {
			index: 4,
			title: 'Author'
		},
	},
	{
		path: "/article",
		name: "article",
		component: () => import("../views/article.vue"),
		meta: {
			index: 4,
			title: 'Article'
		},
	},
	{
		path: "/verify",
		name: "verify",
		component: () => import("../views/verify.vue"),
		meta: {
			index: 4,
			title: 'Verify'
		},
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/about.vue"),
		meta: {
			index: 5,
			title: 'About'
		},
	},
	{
		path: "/Help",
		name: "Help",
		component: () => import("../views/Help.vue"),
		meta: {
			index: 6,
			title: 'Help & Support'
		},
	},
	{
		path: "/addVerify",
		name: "addVerify",
		component: () => import("../views/addVerify.vue"),
		meta: {
			index: 7,
			title: 'AddVerify'
		},
	},
	{
		path: "/journalSource",
		name: "journalSource",
		component: () => import("../views/journal/journalSource.vue"),
		meta: {
			index: 8,
			title: 'JournalSource'
		},
	},
	{
		path: "/journalDetails",
		name: "journalDetails",
		component: () => import("../views/journal/journalDetails.vue"),
		meta: {
			index: 9,
			title: 'JournalDetails'
		},
	},
	{
		path: "/customers",
		name: "customers",
		component: () => import("../views/widget/customers.vue"),
		meta: {
			index: 10,
			title: 'Customers'
		},
	},
	{
		path: "/works",
		name: "works",
		component: () => import("../views/widget/works.vue"),
		meta: {
			index: 10,
			title: 'Works'
		},
	},
	{
		path: "/price",
		name: "price",
		component: () => import("../views/widget/price.vue"),
		meta: {
			index: 10,
			title: 'Price'
		},
	},
	
	{
		path: "/ApplyPlugin",
		name: "ApplyPlugin",
		component: () => import("../views/widget/ApplyPlugin.vue"),
		meta: {
			index: 11,
			title: 'ApplyPlugin'
		},
	},
	{
		path: "/policy",
		name: "policy",
		component: () => import("../views/policy.vue"),
		meta: {
			index: 12,
			title: 'policy'
		},
	},

];
// const router = createRouter({
// 	// history: createWebHashHistory(),
// 	history: createWebHistory(),
// 	routes,
// });
const router = new VueRouter({
	 mode: 'history',
	// history: createWebHashHistory(),
	// history: createWebHistory(),
	routes,
});

export default router;
