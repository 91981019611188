var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layui-bg-gray zong",staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"layui-container",staticStyle:{}},[_c('div',{staticClass:"layui-row",staticStyle:{"max-width":"1200px","margin":"40px auto","margin-bottom":"0px"}},[_c('div',{staticClass:"layui-col-md3"},[_c('div',{staticStyle:{"text-align":"justify","margin-top":"50px","line-height":"24px"}},[_c('div',{},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":require('@/assets/images/logo.png')}})]),_vm._m(0),_vm._m(1),_vm._m(2),_c('p')])]),_c('div',{staticClass:"layui-col-md4"},[_c('div',{staticStyle:{"margin":"78px 0px 0px 80px","line-height":"24px"}},[_vm._m(3),_c('div',{staticClass:"bottom_middle"},[_c('img',{attrs:{"src":require('@/assets/images/position.png'),"alt":""}}),_c('span',[_vm._v("B-7-28 Horizon Suites Blok B, Kota Warisan 43900 Sepang, Selangor, Malaysia")])]),_c('div',{staticClass:"bottom_middle"},[_c('img',{staticStyle:{"width":"17px","height":"13px"},attrs:{"src":require('@/assets/images/email.png'),"alt":""}}),_vm._m(4)])])]),_c('div',{staticClass:"layui-col-md4"},[_c('div',{staticStyle:{"margin":"96px 0px 0px 80px","line-height":"24px"}},[_vm._m(5),_c('p',{staticStyle:{"color":"hsl(220deg,1.86%,68.43%)"}},[_vm._v("Please follow our activities on the following social media platforms")]),_c('div',{staticClass:"bottom_right"},[_c('img',{attrs:{"src":require('@/assets/images/f.png'),"alt":""}}),_c('img',{attrs:{"src":require('@/assets/images/fly.png'),"alt":""}}),_c('img',{attrs:{"src":require('@/assets/images/in.png'),"alt":""}}),_c('img',{attrs:{"src":require('@/assets/images/camera.png'),"alt":""}})])])])]),_c('div',{staticStyle:{"max-width":"1200px","margin":"40px auto","margin-bottom":"0px","padding":"10px 0px","color":"#fff","display":"flex"}},[_vm._m(6),_c('div',{staticStyle:{"margin-left":"452px","cursor":"pointer"},on:{"click":_vm.tipPolicy}},[_vm._v("Terms of use | Privacy policy")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"20px","color":"hsl(220deg,1.86%,68.43%)","text-indent":"26px"}},[_vm._v(" Contrimetric is shortened from Contribution Metric, which is called Article Citation Contribution Indicator(ACCI), or Contribution Factor(CF). Each academic paper has two ACCI values: ACCI-I(CF1) and ACCI-II(CF2), which is a new designed indicator for scientific contribution. "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"color":"hsl(220deg,1.86%,68.43%)","text-align":"justify"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Publication 1:")]),_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v(" Contrimetric: Article Citation Contribution Indicator for Paper Impact Evaluation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"color":"hsl(220deg,1.86%,68.43%)"},attrs:{"href":"http://dx.doi.org/10.1017/S1092852923006181"}},[_vm._v("http://dx.doi.org/10.1017/S1092852923006181")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom_title"},[_c('strong',[_vm._v("CONTACT INFO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a',{staticStyle:{"color":"hsl(220,1.86%,68.43%)"},attrs:{"target":"_top","href":"mailto:794532995@qq.com?cc=123456@qq.com&bcc=7890@qq.com&subject=Hello%20World&body=这是我用超链接创建的邮件!"}},[_vm._v(" contact@contrimetric.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom_title"},[_c('span',{staticStyle:{"display":"flex","width":"375px","font-size":"26px","color":"hsl(182.4deg, 64.1%, 92.35%)"}},[_c('strong',[_vm._v("SOCIAL MEDIA")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_vm._v("Copyright © 2024 All Rights Reserved by "),_c('span',{staticStyle:{"color":"aqua"}},[_vm._v("Contrimetric")])])
}]

export { render, staticRenderFns }